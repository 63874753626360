@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"), url(/thor-assets/fonts/Gotham-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Bold";
  src: local("Gotham-Bold"), url(/thor-assets/fonts/Gotham-Bold.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "Gotham-Medium", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
